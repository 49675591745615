$blue: #59B9FF;
$gray: #3B4856;

* {
    margin: 0;
    padding: 0;
}

html, body, #root, .App {
    height: 100%;

}

a {
    color: inherit;
    text-decoration: none;
    &:visited {
      color: inherit;
      text-decoration: none;
    }
  }

.dark-icon {
    svg {
        fill: black;
    }
}

.center-icon {
    display: flex;
    align-items: center;
    a {
        margin-left: 1rem;
    }
}

.navbar {
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    height: 80px;
    margin-bottom: -80px;
    display: flex;
    align-items: center;

    .title {
        font-family: monsterrat-bold;
        font-weight: 700;
        font-size: 2rem;
    }

    .icon-button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        svg {
            height: 20px;
            width: 20px;
        }
    }

    .link-container {
        margin-left: auto;
        padding: 0;
        display: flex;
        align-items: center;
        align-content: flex-end;
        float: right;
        .links {
            a {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                list-style-type: none;
                padding: 0.7rem;
                font-size: 1rem;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                &.active {
                    background: $blue;
                    color: white;
                }
            }

            .icon-button {
                svg {
                    fill: white;
                }
            }
        }
        
        button {
            display: none;
        }
    }
}

.container {
    min-height: 100%;
}

.customers-info {
    padding: 0.2em;
    border-radius: 0.3em;
    margin-bottom: 1em;
    color: white;
    background-color: red;
    width: 100%;
    font-weight: normal;

    .highlight {
        font-weight: bold;
    }    
}

.page-title {
    font-family: monsterrat-bold;
    font-weight: 700;
    font-size: 50px;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 64px;
        margin-right: 5px;
        background: $blue;
    }           
}   

.page-text {
    margin-top: 1rem ; 

    .small {
        width: 80%;
    }
}

.bold {
    font-family: monsterrat-bold;
    font-size: 1.5rem;
}

.link {
    color: $blue;

    &:visited {
        color: $blue;
    }
}

.page-content-wrapper {
    overflow: auto;
    padding-left: 15rem;
    padding-right: 15rem;
    padding-top: 80px;
    padding-bottom: 110px;

    display: flex;
    height: 100%;

    .page-content-left {
        margin-right: 2rem;
    }

    .page-content-right {
        height: 30rem;
        margin-bottom: 2rem;

        img{
            height: 100%;
            object-fit: cover;
        }
    }
}

.pin-image {
    height: 8rem;
    margin-left: auto;
    img{
        height: 100%;
        object-fit: cover;
    }
}

.list-container{
    display: grid;
    grid-template-columns: auto auto auto;
    .card {
        border: 1px solid rgba(0, 0, 0, 0.8);
        margin: 1rem;
        padding: 1rem;

        .card-title {
            font-family: monsterrat-bold;
            font-size: 1.5rem;
        }

        .card-price {
            font-family: monsterrat-bold;
            font-size: 1.2rem;
            color: $blue;
        }
    }
}

.footer {
    position:relative;
    height: 110px;
    margin-top: -110px;
    clear: both;
    width:100%;
    
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;

    .footer-content {
        height: 100%;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .contact {
            font-family: monsterrat-bold;
        }

        svg {
            margin-right: 1rem;
        }

        span {
            margin-bottom: 0.2rem;
            display: flex;
            justify-content: left;
        }
    }
    
    .footer-icon {
        height: 60%;
        color: white;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-left: 1rem;

        svg {
            width: 150px;
            height: auto;
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 1450px) {
    .page-content-wrapper {
        padding-left: 10rem;
        padding-right: 10rem;
    }
    
    .list-container{
        grid-template-columns: auto auto;
    }
}


@media only screen and (max-width: 1200px) {
    .page-content-wrapper {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .navbar {
        .title {
            font-size: 2.5rem !important;
        }
        .link-container {
            .links {
                a {
                    margin-left: 0.1rem !important;
                    margin-right: 0.1rem !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .navbar {
        .link-container {
            .links {
                a {
                    font-size: 0.9rem !important;
                }
            }
        }
    }

    .list-container{
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 950px) {
    .navbar {
        .title {
            font-size: 2rem !important;
        }
    }
}

@media only screen and (max-width: 850px) {
    .navbar {
        .link-container {
            .links {
                display: none !important;
            }
        }
        button {
            display: flex !important;
        }
        #hidden {
            position: absolute;
            left: 0px;
            top: 0;
            height: 250px;
            max-height: 250px;
            width: 100%;
            background-color: $blue;
            display: flex !important;
            flex-direction: column;
            align-items: center;
            padding-top: 2rem;
            a {
                font-size: 1rem !important;
            }
        }
    }

    .page-content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    .list-container{
        grid-template-columns: auto;
    }
}

@media only screen and (max-width: 515px) {

    .page-content-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .page-content-wrapper {
        overflow: hidden;
    }

    .page-title {
        font-size: 35px !important;
    }

    .footer-icon {
        svg {
            width: 100px !important;
        }
    }
}