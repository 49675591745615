* {
  margin: 0;  
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: monsterrat;
}

@font-face {
  font-family: monsterrat;
  src: url(./fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: monsterrat-bold;
  src: url(./fonts/Montserrat-Bold.ttf);
}